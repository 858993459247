import { ImageType } from '@/__generated__/gateway/graphql';

export const MIMETYPE_PNG_FILE = 'image/png' as const;
export const MIMETYPE_JPEG_FILE = 'image/jpeg' as const;
export const MIMETYPE_PDF_FILE = 'application/pdf' as const;

export const EXTENSION_PNG_FILE = '.png' as const;
export const EXTENSION_JPEG_FILES = '.jpg,.jpeg' as const;
export const EXTENSION_PDF_FILE = '.pdf' as const;

export const IMAGE_MIME_TYPES = [MIMETYPE_PNG_FILE, MIMETYPE_JPEG_FILE];

export const DOCUMENT_MIME_TYPES = [MIMETYPE_PDF_FILE];

export type ImageMimeType = (typeof IMAGE_MIME_TYPES)[number];

export type DocumentMimeType = (typeof DOCUMENT_MIME_TYPES)[number];

export type AllMimeTypes = ImageMimeType | DocumentMimeType;

const IMAGE_MIMETYPE_EXTENSION_MAP: Record<ImageMimeType, string> = {
  [MIMETYPE_PNG_FILE]: EXTENSION_PNG_FILE,
  [MIMETYPE_JPEG_FILE]: EXTENSION_JPEG_FILES
};

const DOCUMENT_MIMETYPE_EXTENSION_MAP: Record<DocumentMimeType, string> = {
  [MIMETYPE_PDF_FILE]: EXTENSION_PDF_FILE
};

const MIMETYPE_EXTENSION_MAP: Record<AllMimeTypes, string> = {
  ...IMAGE_MIMETYPE_EXTENSION_MAP,
  ...DOCUMENT_MIMETYPE_EXTENSION_MAP
};

export const MAX_IMAGE_SIZE = 5 * 1024 * 1024; //5 Mb

const getExtensionsFromMimeType = (mimeType: AllMimeTypes): string =>
  MIMETYPE_EXTENSION_MAP[mimeType];

// produces a combined mime-type & file extension accepts-string for inputs,
// such as 'image/png,.png,image/jpeg,.jpg,.jpeg'
export const getInputAcceptString = (mimeTypes: AllMimeTypes[]): string =>
  mimeTypes.reduce(
    (acc, cur) => (acc += `${cur},${getExtensionsFromMimeType(cur)}`),
    ''
  );

export const imageMimeTypeToImageType = (mimeType: string) => {
  switch (mimeType) {
    case MIMETYPE_JPEG_FILE:
      return ImageType.Jpg;
    case MIMETYPE_PNG_FILE:
      return ImageType.Png;
  }

  return null;
};

export const isSupportedImageFileType = (file: File) =>
  (IMAGE_MIME_TYPES as string[]).includes(file.type);

export const isPdfFileType = (file: File) => file.type === MIMETYPE_PDF_FILE;

export const isDocumentFileType = (file: File) =>
  (DOCUMENT_MIME_TYPES as string[]).includes(file.type);

export const isValidImageSize = (file: File): boolean =>
  file.size <= MAX_IMAGE_SIZE;
